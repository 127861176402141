html,body {
    height: 100vh;
    width: 100vw;
}

::-webkit-scrollbar {
    width: 8px;
}


::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
}


::-webkit-scrollbar-thumb {
    background: #ff7575;
    border-radius: 5px;
}
  

::-webkit-scrollbar-thumb:hover {
    background: #555;
}



.calendar-screen{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
}

/* block day component */
.block-day-button {
    margin-bottom: 1rem;
}

.block-date-modal {
    padding: 1rem;
}
/* end block day component */

.rbc-calendar {
    height: 100%;
}

.rbc-events-container{
    display:flex;
}

.rbc-allday-cell {
    display:none;
}


.rbc-day-slot .rbc-events-container {
    margin-right: 0;
}


.rbc-event {
    border-color: transparent !important;
    background-color: transparent;
    padding: 2px 2px;
    font-size: 12px;
    
}


.rbc-day-slot .rbc-time-column{
    display:flex;
}


.rbc-event-label {
    display: none;
}

.rbc-timeslot-group, .rbc-time-slot {
    min-height: 50px;
}

.rbc-time-gutter > .rbc-timeslot-group {
    min-height: 50px;
}

.shift {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    text-transform: capitalize;
}

/* Statuses */
.shift-state   {
    padding: 2px 4px;
    color: white;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: .6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: flex-start;  
}

.pendiente {
    border-radius: 3px 6px 6px 3px;
    border-left: 3px solid rgb(98, 98, 98);
    background-color: rgba(139, 139, 139, 1); 
    padding: 5px;
    color:  rgb(41, 40, 40);
    min-height: 3rem;
    transition: .5s ease;
}

.pendiente .shift-state {
    background-color: rgb(41, 40, 40);
}

.pendiente:hover {
    background-color: rgba(139, 139, 139, .5);
    box-shadow: 0px 10px 8px 0px #555;
    transform: scale(.9);
}

.en-proceso {
    border-radius: 3px 6px 6px 3px;
    border-left: 3px solid rgb(77, 138, 250);
    background-color: rgb(129, 173, 255); 
    padding: 5px;
    color:  rgb(39, 35, 245);
    min-height: 3rem;
    transition: .5s ease;
}

.en-proceso .shift-state {
    background-color: rgb(39, 35, 245);
}

.en-proceso:hover {
    background-color: rgba(41, 110, 238, .5);
    box-shadow: 0px 10px 8px 0px #555;
    transform: scale(.9);
}

.ausente {
    border-radius: 3px 6px 6px 3px;
    border-left: 3px solid rgb(219, 75, 30);
    background-color: rgb(255, 95, 95); 
    padding: 5px;
    color: rgb(110, 27, 2);
    min-height: 3rem;
    transition: .5s ease;
}

.ausente .shift-state {
    background-color: rgb(167, 39, 0);
}

.ausente:hover {
    background-color: rgba(219, 54, 54, .5);
    box-shadow: 0px 10px 8px 0px #555;
    transform: scale(.9); 
}

.finalizado {
    border-radius: 3px 6px 6px 3px;
    border-left: 3px solid rgb(14, 216, 14);
    background-color: rgb(201, 253, 188); 
    padding: 5px;
    color: rgb(0, 149, 0);
    min-height: 3rem;  
    transition: .5s ease;
}

.finalizado .shift-state {
    background-color: rgb(0, 149, 0);
}



.finalizado:hover {
    background-color: rgba(174, 238, 158,.5);
    box-shadow: 0px 10px 8px 0px #555;
    transform: scale(.9);
}

.shift-detail {
    width: 100%;
    display: flex;
    justify-content: space-between;
}


.rbc-selected {
    padding: 1px 2px 0 2px;
    background-color: #ff7575 !important;
}



/* end statuses */

@media only screen and (max-width: 600px) {
    .rbc-event {
        display: flex;
        flex-direction: column;
        height: 47px !important;
        width: 100%;
    }
  }

/* Swal */
.swal2-container {
    z-index: 10000;
  }
/* end Swal */

/* Sidebar */
.linkButton {
    color:white;
    text-decoration: none;
}

.menuIcon {
    color: white;
}
/* end Sidebar */

/* Data grid */
.MuiDataGrid-columnsContainer {
    background: linear-gradient(45deg, #FF8E53 20%, #FE6B8B 80%);
    color: white;
}


.add-patient-button {
    background-color: #FF8E53 !important;
    color: white !important;
    cursor: pointer;
}

.MuiMenuItem-root {
    display: flex !important;
    justify-content: space-between !important;
}

.patientLink {
    text-decoration: none;
    color: #555;
}

.patientLink:visited, .patientLink:active {
    color: #555;
}

/* end Data grid */

/* shift form */
.hidden {
    display: none !important;
}

.show {
    display: block;
}


/* patient detail */

.empty-historial {
    text-align: center;
    color: #5f5f5f;
}

/*********** statics **************/

.statics {
    padding: 1rem;
    text-align: center;
}
